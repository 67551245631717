import { endpoint } from 'constants/endpoint';
import { deviceInfo, getCurrentSession } from 'utils/function';
import { Request, hideLoader } from 'services/api/network';
import { ViewerColorsConstants } from 'constants/appConstant';
import { AppConstants } from 'constants/appConstant';
import { getLocalStorageItem } from 'services/others/storageServices';

export const getAdminOrganizationsList = async () => {
  try {
    const response = await Request.get(
      `${
        endpoint.adminOrganizationList
      }?_ANALYTICS=${deviceInfo()}&konstruSessionId=${getCurrentSession()}`,
      hideLoader
    );
    if (response?.status === 200) {
      return response?.data;
    }
    if (response?.response?.status === 401) {
      return response?.response?.data;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const getAllUsersList = async () => {
  try {
    const response = await Request.get(
      `${
        endpoint.allUsersList
      }?_ANALYTICS=${deviceInfo()}&konstruSessionId=${getCurrentSession()}`,
      hideLoader
    );
    if (response?.status === 200) {
      return response?.data;
    }
    if (response?.response?.status === 401) {
      return response?.response?.data;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const fuzzySearchForUser = async (searchTerm) => {
  const response = await Request.get(
    `${
      endpoint.fuzzySerachForUser
    }?_ANALYTICS=${deviceInfo()}&konstruSessionId=${getCurrentSession()}&searchTerm=${searchTerm}`,
    hideLoader
  );
  return response.data;
};

export const getUser = async (userId) => {
  const response = await Request.get(
    `${
      endpoint.getUser
    }?_ANALYTICS=${deviceInfo()}&konstruSessionId=${getCurrentSession()}${userId}`,
    hideLoader
  );
  return response.data;
};

export const updateTestingGrp = async (data) => {
  try {
    const response = await Request.post(
      `${
        endpoint.updateTestingGroup
      }?_ANALYTICS=${deviceInfo()}&konstruSessionId=${getCurrentSession()}`,
      { data },
      hideLoader
    );

    if (response?.status === 200) {
      return response?.data;
    }
    if (response?.response?.status === 401) {
      return response?.response?.data;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const getKonstruAdmins = async () => {
  try {
    const response = await Request.get(
      `${
        endpoint.getKonstruAdmins
      }?_ANALYTICS=${deviceInfo()}&konstruSessionId=${getCurrentSession()}`,
      hideLoader
    );
    if (response?.status === 200) {
      return response?.data;
    }
    if (response?.response?.status === 401) {
      return response?.response?.data;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const createColorScheme = (colorschemes) => {
  return {
    repair: {
      value: colorschemes.repair,
      colors: ViewerColorsConstants[colorschemes.repair]
    },
    default: {
      value: colorschemes.default,
      colors: ViewerColorsConstants[colorschemes.default]
    }
  };
};

export const getViewerPreferences = () => {
  let currentUser = getLocalStorageItem('currentUser');
  if (currentUser) {
    return {
      colorSchemes: currentUser?.preferences?.colorSchemes
        ? createColorScheme(currentUser.preferences.colorSchemes)
        : createColorScheme(AppConstants.defaultViewerPreferences.colorSchemes),
      testingGroup: currentUser?.preferences?.testingGroup
        ? currentUser.preferences.testingGroup
        : {}
    };
  } else {
    return {
      colorSchemes: createColorScheme(
        AppConstants.defaultViewerPreferences.colorSchemes
      ),
      testingGroup: {}
    };
  }
};

export const getProfileSettingData = async (userId) => {
  try {
    const response = await Request.get(
      `${
        endpoint.getProfileSettingData
      }/${userId}?_ANALYTICS=${deviceInfo()}&konstruSessionId=${getCurrentSession()}`,
      hideLoader
    );
    if (response?.status === 200) {
      return response?.data;
    }
    if (response?.response?.status === 401) {
      return response?.response?.data;
    }
  } catch (error) {
    if (error?.response?.data) {
      return error?.response?.data;
    }
  }
};

export const updateUserState = async (userId, data) => {
  try {
    let response = await Request.put(
      `${endpoint.updateUserState}/${userId}/updateUserState`,
      data
    );
    if (response?.data?.success) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return customToast('error', error.message);
  }
};
